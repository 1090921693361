import type { NextPage } from "next";
import { useRouter } from "next/router";
import Dashboard from "../components/Dashboard";
import BrowserTitle from "../components/BrowserTitle";
import { useEffect } from "react";
import SpinnerCustom from "../components/SpinnerCustom";

const Home: NextPage = () => {
  const router = useRouter();
  const envString = `${process.env.NEXT_PUBLIC_LABS_INSTANCE}` || "default";

  useEffect(() => {
    let deferredPrompt: any;
    const addBtn = document.querySelector(".add-button") as HTMLElement;

    window.addEventListener("beforeinstallprompt", (e: any) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI to notify the user they can add to home screen
      addBtn.style.display = "block";

      addBtn.addEventListener("click", (e) => {
        // hide our user interface that shows our A2HS button
        addBtn.style.display = "none";
        // Show the prompt
        deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice.then((choiceResult: any) => {
          if (choiceResult.outcome === "accepted") {
            console.log("User accepted the A2HS prompt");
          } else {
            console.log("User dismissed the A2HS prompt");
          }
          deferredPrompt = null;
        });
      });
    });
  }, []);

  const onVVHomePage =
    (envString === "vvseai" || envString === "vvhc") && router.pathname === "/";

  useEffect(() => {
    if (onVVHomePage) {
      router.push("/dealflow");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envString]);

  if (onVVHomePage) {
    return <SpinnerCustom />;
  } else {
    return (
      <>
        <BrowserTitle navigation="/" />
        <Dashboard />
      </>
    );
  }
};

export default Home;
