import React from "react";
import { useCurrentUser } from "./AuthProvider";
import Avatar from "./Avatar";
import { Companies } from "./Dashboard/Companies";
import { DashboardUpdates } from "./Dashboard/DashboardUpdates";
import { DataOverview } from "./Dashboard/DataOverview";
import { NetWorkCollaboration } from "./Dashboard/NetWorkCollaboration";
import LatestNews from "./LatestNews";

const Dashboard: React.FC = () => {
  const { user: currentUser } = useCurrentUser();

  return (
    <div className="-mt-6 flex h-[90vh] w-full px-2 pt-6">
      <div className="show-scrollbar !flex !h-full !w-full flex-col overflow-auto px-4 lg:overflow-hidden">
        <div className="mb-5 flex flex-row items-center">
          <div className="ml-4 mr-4">
            <Avatar
              name={currentUser.firstName}
              size={30}
              className="lg:hidden"
              url={currentUser.image_url}
            />
            <Avatar
              name={currentUser.firstName}
              size={40}
              className="hidden lg:flex"
              url={currentUser.image_url}
            />
          </div>
          <div className="text-3xl font-bold">
            Hello, {currentUser.firstName}!
            <div className="h-1 w-24 rounded-full bg-blue-menu" />
          </div>
        </div>

        <div className="flex max-h-[78vh] flex-col gap-3 md:h-full lg:flex-row">
          <div className="flex flex-col gap-y-3 lg:w-2/3">
            <div className="flex h-full w-full flex-col gap-y-3 sm:flex-row sm:gap-x-3 md:h-[calc(78vh-98px)]">
              <Companies />
              <DashboardUpdates />
            </div>
          </div>
          <div className="flex h-full max-h-[82vh] min-w-[270px] flex-col gap-y-3 sm:flex-row sm:gap-y-0 sm:gap-x-3 lg:w-1/3 lg:flex-col lg:gap-x-0">
            <LatestNews />
            <NetWorkCollaboration />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
