import { Tab } from "@headlessui/react";
import Link from "next/link";
import { useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import { getter } from "../../api";
import {
  CommentDetailWithReference,
  DashboardOpportunities,
  MeetingNote,
  OrganizationAutocompleteResult,
  PaginatedList,
  Success,
  UserAutocompleteResult,
} from "../../api/types";
import Avatar from "../Avatar";
import SpinnerCustom from "../SpinnerCustom";
import { classNames as classes } from "../utils";
import { dateFormatDDMMMYYYY } from "../Utils/commons";
import { options } from "./DashboardUltils";

export const DashboardUpdates = () => {
  const [index, setIndex] = useState(0);

  const { data: comments, isValidating: loadingComments } = useSWR<
    Success<CommentDetailWithReference[]>
  >("/api/users/comments?limit=20", getter, options);

  const { data: notes, isValidating: loadingNotes } = useSWR<
    Success<PaginatedList<MeetingNote>>
  >("/api/people_map/meeting_notes?limit=20", getter, options);

  const { data: opportunities, isValidating: loadingOpps } = useSWR<
    Success<DashboardOpportunities[]>
  >("/api/people_map/opportunities/recent?limit=20", getter, options);

  const { data: users, isValidating: loadingUsers } = useSWR<
    UserAutocompleteResult[]
  >("/api/users/autocomplete/users?q=&limit=1000", getter, options);

  useEffect(() => {
    const prevTab = localStorage.getItem("dashboardUpdatesTab") ?? "0";
    setIndex(parseInt(prevTab));
  }, []);

  const tabHeaders = useMemo(() => {
    return [
      {
        index: 0,
        name: "Comments",
      },
      {
        index: 1,
        name: "Opportunities",
      },
      {
        index: 2,
        name: "Notes",
      },
    ];
  }, []);

  const renderMentions = (text: string) => {
    if (users && users.length) {
      const regex = /@\[([^\]]+)\]\((\d+)\)/g;
      let match;
      let lastIndex = 0;
      const elements = [];

      while ((match = regex.exec(text)) !== null) {
        const mentionId = parseInt(match[2], 10);
        const mention = users.find((m) => m.value === mentionId);

        if (mention) {
          if (match.index > lastIndex) {
            elements.push(
              <span key={`text-${lastIndex}`}>
                {text.substring(lastIndex, match.index)}
              </span>,
            );
          }
          elements.push(
            <span
              key={`mention-${mentionId}`}
              className="m-0.5 rounded-[2px] bg-[#e0e0e0] px-1 py-0.5"
            >
              {mention.label}
            </span>,
          );
          lastIndex = regex.lastIndex;
        }
      }
      if (lastIndex < text.length) {
        elements.push(
          <span key={`text-${lastIndex}`}>{text.substring(lastIndex)}</span>,
        );
      }
      return elements;
    } else {
      return text;
    }
  };

  return (
    <div className="border-1 flex h-full max-h-[72vh] w-full min-w-[270px] flex-row items-start rounded-lg border bg-white p-4 !pr-0 shadow-sm">
      <Tab.Group
        selectedIndex={index}
        onChange={(index) => {
          setIndex(index);
          localStorage.setItem("dashboardUpdatesTab", index.toString());
        }}
        defaultIndex={index}
      >
        <div className="flex h-full w-full flex-col">
          <div className="my-3 flex flex-row items-end items-center justify-between pr-4">
            <Tab.List className="flex w-full items-center justify-between">
              <div className="flex">
                <span className="mr-2 h-5 w-2 bg-blue-menu"></span>
                <div className="mr-2 text-sm font-bold sm:text-xs md:text-sm">
                  Updates
                </div>
              </div>
              <div className="flex w-auto items-center overflow-x-auto border-b-[1px] border-gray-300 sm:gap-x-1 lg:gap-x-2">
                {tabHeaders.map((tabHeader) => (
                  <div key={tabHeader.index} className="group">
                    <Tab
                      className={({ selected }) =>
                        classes(
                          "whitespace-nowrap px-1 text-xs font-medium focus:outline-none focus:ring-0 sm:text-2xs md:text-xs",
                          selected || tabHeader.index === index
                            ? "text-blue-menu"
                            : "text-gray-500 group-hover:text-gray-700",
                        )
                      }
                    >
                      {tabHeader.name}
                    </Tab>
                    <div
                      className={`h-1 w-full rounded-t-md ${
                        tabHeader.index === index
                          ? "bg-blue-menu"
                          : "group-hover:bg-gray-300"
                      }`}
                    ></div>
                  </div>
                ))}
              </div>
            </Tab.List>
          </div>
          <Tab.Panels className="show-scrollbar !h-fit overflow-auto pr-6 sm:h-[93%]">
            <Tab.Panel>
              <div className="flex flex-col text-xs">
                {!loadingComments && !loadingUsers ? (
                  comments &&
                  comments.data?.map((comment: CommentDetailWithReference) => (
                    <Link
                      className="flex flex-col gap-x-2 gap-y-2 border-b-[1px] border-gray-300 py-2 text-black"
                      key={comment.id}
                      href={
                        comment.reference?.url ? comment.reference?.url : ""
                      }
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-x-2">
                          <Avatar
                            name={comment.user?.name}
                            size={32}
                            url={comment.user?.image_url}
                            className="rounded-full shadow-sm"
                          />
                          <div className="flex flex-col">
                            <div className="font-bold">
                              {comment.user?.name}
                            </div>
                            <div className="flex">
                              <div className="mr-1">on </div>
                              <div className="font-bold">
                                {comment.reference?.name}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="whitespace-nowrap text-2xs font-light text-black">
                          {dateFormatDDMMMYYYY(comment.created_at)}
                        </div>
                      </div>
                      <div
                        className={`max-h-[50px] line-clamp-2 ${
                          comment.text.includes("@") && "leading-6"
                        }`}
                      >
                        {renderMentions(comment.text)}
                      </div>
                    </Link>
                  ))
                ) : (
                  <SpinnerCustom />
                )}
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="flex flex-col text-xs">
                {!loadingOpps ? (
                  opportunities &&
                  opportunities.data.map(
                    (opportunity: DashboardOpportunities) => (
                      <div
                        className="flex flex-col gap-x-2 gap-y-2 border-b-[1px] border-gray-300 py-2 text-black"
                        key={opportunity.value}
                      >
                        <div className="flex flex-row gap-x-2">
                          <div className="flex gap-x-2">
                            {opportunity.organizations?.map(
                              (org: OrganizationAutocompleteResult) => (
                                <Link
                                  href={`/organizations/${org.value}`}
                                  key={org.value}
                                >
                                  <Avatar
                                    size={50}
                                    name={org.label}
                                    url={org.image_url}
                                    className="rounded-none"
                                  />
                                </Link>
                              ),
                            )}
                          </div>
                          <div>
                            <Link href={`/opportunity/${opportunity.value}`}>
                              <div className="font-bold text-black">
                                {opportunity.label}
                              </div>
                            </Link>
                            <div className="flex">
                              <div className="mr-1">By </div>
                              <Link href={`/people/${opportunity.owner?.id}`}>
                                <div className="font-bold text-black">
                                  {opportunity.owner?.name}
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center justify-between">
                          <div className="text-gray-500">
                            {opportunity.opportunity_type}
                          </div>
                          <div>
                            {opportunity.funnel_stage && (
                              <div className="text-gray-500">
                                {" "}
                                {opportunity.funnel_stage}{" "}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ),
                  )
                ) : (
                  <SpinnerCustom />
                )}
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="flex flex-col text-xs">
                {!loadingNotes ? (
                  notes &&
                  notes.data?.items?.map((note: MeetingNote) => (
                    <Link
                      className="flex flex-col gap-x-2 gap-y-2 border-b-[1px] border-gray-300 py-2 text-black"
                      key={note.id}
                      href={`/meeting_notes/${note.id}`}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-x-2">
                          <Avatar
                            name={note.owner.name}
                            size={32}
                            url={note.owner.image_url}
                            className="rounded-full shadow-sm"
                          />
                          <div className="font-bold">{note.owner.name}</div>
                        </div>
                        <div className="whitespace-nowrap text-2xs font-light text-black">
                          {dateFormatDDMMMYYYY(note.date)}
                        </div>
                      </div>
                      <div className="flex flex-col gap-y-2">
                        <div className="font-bold line-clamp-2">
                          {note.title}
                        </div>
                        <div className="break-normal line-clamp-2">
                          {note.content}
                        </div>
                      </div>
                    </Link>
                  ))
                ) : (
                  <SpinnerCustom />
                )}
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </div>
      </Tab.Group>
    </div>
  );
};
