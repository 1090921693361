type NoResultProps = {
  message?: string;
};

export const NoResult = ({ message }: NoResultProps) => {
  return (
    <div className="grid justify-center">
      <div className="flex flex-col items-center">
        <img
          src="/no-result-search.png"
          alt={message}
          className="w:48 -mt-10 h-48 md:-mt-16 md:h-96 md:w-96"
        />
        <div className="-mt-4 text-xs font-semibold md:-mt-12 md:text-base">
          {message}
        </div>
      </div>
    </div>
  );
};
